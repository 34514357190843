import { IconCancel, IconResume, IconUpload } from '../icons';
import React, { useMemo } from 'react';
import { useDropzone, type DropzoneOptions } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

const variants = {
  base: 'bg-frosted-dark-gradient frosty backdrop-blur rounded-md cursor-pointer border-2 border-dashed border-white transition-colors duration-200 ease-in-out my-1 mb-3',
  image: 'border-0 p-0 min-h-0 min-w-0 shadow-md bg-slate-200 rounded-md',
  active: 'border-2',
  disabled: 'bg-gray-200 border-gray-300 cursor-default pointer-events-none bg-opacity-30 opacity-50',
  accept: 'border border-blue-500 bg-blue-500 bg-opacity-10',
  reject: 'border border-red-700 bg-red-700 bg-opacity-10',
  darkBase: 'bg-dark-main rounded-md cursor-pointer border-2 border-dashed border-white transition-colors duration-200 ease-in-out my-1 mb-3',
};

interface InputProps {
  width: number
  height?: number
  className?: string
  value?: File[] | string[]
  onChange?: (file?: File[]) => void | Promise<void>
  disabled?: boolean
  dropzoneOptions?: Omit<DropzoneOptions, 'disabled'>
  maxFiles?: number
  hideFiles?: boolean
  darkMode?: boolean
  onlyPdf?: boolean
}

export const FileDropzone =
  (
    { dropzoneOptions, width, height, value, className, disabled, onChange, maxFiles, hideFiles, darkMode, onlyPdf }: InputProps
  ) => {
    const imageUrl = useMemo(() => {
      return value?.map((file) => {
        if (typeof file === 'string') {
          // in case an url is passed in, use it to display the image
          return file;
        } else if (file) {
          // in case a file is passed in, create a base64 url to display the image
          return URL.createObjectURL(file);
        }
        return null;
      });
    }, [value]);

    // dropzone configuration
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: { ...(!onlyPdf && { 'image/*': [] }), 'application/pdf': ['.pdf'] },
      multiple: true,
      disabled,
      maxFiles,
      onDrop: (acceptedFiles) => {
        void onChange?.(acceptedFiles);
      },
      ...dropzoneOptions,
    });

    // styling
    const dropZoneClassName = useMemo(
      () =>
        twMerge(
          darkMode ? variants.darkBase : variants.base,
          isFocused && variants.active,
          disabled && variants.disabled,
          imageUrl && variants.image,
          (isDragReject ?? fileRejections) && variants.reject,
          isDragAccept && variants.accept,
          className
        ).trim(),
      [
        isFocused,
        imageUrl,
        fileRejections,
        isDragAccept,
        isDragReject,
        disabled,
        className,
        darkMode
      ]
    );

    return (
      <div className='flex flex-col gap-1'>
        <div
          {...getRootProps({
            className: dropZoneClassName,
            style: {
              width,
              ...(height && { height }),
            },
          })}
        >
          <input {...getInputProps()} />
          <div className='min-h-full flex flex-col items-center justify-center py-2' style={{ minWidth: width }}>
            <IconUpload className='h-7 w-7' />
            <div className='text-center text-sm text-white font-gothamBook'>
              {onlyPdf ? 'Cargar pdf' : 'Cargar pdf o imagen'}
            </div>
          </div>
        </div>

        {
          !hideFiles &&
            <div className='flex flex-row items-center w-full gap-2'>
              {
                acceptedFiles.map((file) => (
                  <div key={file.name} className='flex flex-col h-full items-center justify-center px-3 gap-2 min-w-36 w-36'>
                    <IconResume width={24} height={24} />
                    <div className='text-sm align-middle text-center content-center w-full h-16 text-ellipsis overflow-hidden break-words'>
                      {file.name}
                    </div>
                  </div>))
              }
            </div>
        }

        {imageUrl && !disabled && (
          <div
            className='group absolute right-0 top-0 -translate-y-1/4 translate-x-1/4 transform'
            onClick={(e) => {
              e.stopPropagation();
              void onChange?.(undefined);
            }}
          >
            <div className='flex h-5 w-5 items-center justify-center rounded-md border border-solid border-gray-500 bg-white transition-all duration-300 hover:h-6 hover:w-6 dark:border-gray-400 dark:bg-black'>
              <IconCancel
                className='text-gray-500 dark:text-gray-400'
                width={16}
                height={16}
              />
            </div>
          </div>
        )}
        {fileRejections.length > 0 && fileRejections.some(({ errors }) => errors?.some(({ code }) => code === 'file-invalid-type')) &&
          <div className='text-danger text-xs'>
            Tipo de archivo no válido. Solo se permiten imágenes y PDFs.
          </div>}
      </div>
    );
  }
;
