import { extendVariants } from '@nextui-org/react';
import { Select as NextUiSelect, SelectItem as NextUiSelectItem, HiddenSelect, SelectItemProps, SelectProps, SelectSection, SelectSectionProps, SelectedItemProps, SelectedItems, UseSelectProps, useSelect } from '@nextui-org/select';

export const Select = extendVariants(NextUiSelect, {
  variants: {
    color: {
      alfred: {
        listboxWrapper: ['rounded-md bg-complementary-gray-light/10 '],
        popoverContent: ['bg-frosted-gradient backdrop-blur frosty p-0'],
        listbox: ['py-5 px-5'],
        mainWrapper: ['[&>button]:border-none'],
        base: ['bg-secondary-op-gradient-hover font-bold border-1 border-complementary-gray-light rounded-md'],
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export const SelectItem = extendVariants(NextUiSelectItem, {
  variants: {
    color: {
      alfred: {
        base: ['my-1 border-1 border-complementary-gray-light hover:bg-inherit bg-primary-main data-[hover=true]:bg-inherit bg-dark-dark/40 p-3'],
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export {
  HiddenSelect,
  type SelectItemProps,
  type SelectProps,
  type SelectSection,
  type SelectSectionProps,
  type SelectedItemProps,
  type SelectedItems,
  type UseSelectProps,
  useSelect
};
