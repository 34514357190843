import { FC, useCallback, useState } from 'react';
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Popover, Badge, PopoverTrigger, PopoverContent } from '..';
import { IconArrowLeft, IconFilter, IconSrSearch, IconX } from '../../icons';
import { FilterSchemaProperties, SchemaDate } from '../../types';
import { parseDate } from '@internationalized/date';
import InputDebounce from '../InputDebounce';
import EnumDropdown from '../EnumFilter';
import useCustomFilterStore from './CustomFilterStore';
import { DateValue, RangeCalendar, RangeValue, SharedSelection } from '@nextui-org/react';

export interface FilterQuery {
  id: string
  value: string
}

interface CustomFiltersProps {
  filters: FilterSchemaProperties[]
  onFilterChange: (filter: FilterSchemaProperties[], filterQuery: FilterQuery[], filterRemove?: string) => void
  isLoading?: boolean
}

export const CustomFilters: FC<CustomFiltersProps> = ({ filters: originFilters, onFilterChange, isLoading }) => {
  const filters = structuredClone(originFilters);
  const [subFilters, setSubFilters] = useState<string[]>(
    filters.filter((item) => item.value !== undefined).map((item) => item.label)
  );
  const lastCreated = useCustomFilterStore((state) => state.lastSelected);
  const setLastCreated = useCustomFilterStore((state) => state.setLastSelected);

  const onMainSelection = useCallback((keys: SharedSelection) => {
    setSubFilters((prev) => [...prev, keys?.currentKey ?? '']);
    setLastCreated(keys?.currentKey ?? '');
  }, [setLastCreated]);

  const onSelectValue = useCallback((keys: SharedSelection, filter: string, multiple: boolean) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = multiple ? Array.from(keys).map((key) => key as string) : keys?.currentKey ?? '';
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.multiple && item.value ? (item.value as string[]).join(',') : item.value as string,
      })).filter((item) => !!item.value)
    );
  }, [filters, onFilterChange]);

  const onSelectDate = useCallback((value: RangeValue<DateValue>, filter: string) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = {
      start: value.start?.toString() ?? '',
      end: value.end?.toString() ?? '',
    };
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.value as string,
      })).filter((item) => !!item.value)
    );
  }, [filters, onFilterChange]);

  const onInputChange = useCallback((value: string, filter: string) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = value;
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.value as string,
      })).filter((item) => !!item.value)
    );
  }, [filters, onFilterChange]);

  const removeFilter = useCallback((filter: string) => {
    if (isLoading) return;
    setSubFilters((prev) => prev.filter((item) => item !== filter));
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) {
          item.value = undefined;
        }
        return item;
      }),
      filters.map((item) => {
        if (item.label === filter) {
          return { id: item.id, value: '' };
        }
        return { id: item.id, value: item.value as string };
      }).filter((item) => !!item.value),
      filters.find((item) => item.label === filter)?.id
    );
  }, [filters, onFilterChange, isLoading]);

  const itemsFiltered = filters.filter((item) => !subFilters?.some((sub) => sub === item.label));

  return (
    <div className='flex flex-wrap gap-3'>
      <Dropdown
        radius='sm'
        classNames={{
          content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0',
        }}
        placement='bottom-start'
        isDisabled={isLoading}
      >
        <DropdownTrigger>
          <Button
            onPress={() => {}}
            color='GradientAlfredBlue'
            startContent={<IconFilter />}
          >
            Filtros
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label='Custom item styles'
          className='p-3 w-full max-w-full '
          selectionMode='single'
          onSelectionChange={onMainSelection}
          itemClasses={{
            wrapper: ['p-0'],
            description: ['p-0'],
            base: [
              'rounded-md',
              'data-[hover=true]:bg-[#000B2533]',
              'data-[selectable=true]:focus:bg-[#000B2533]'
            ],
          }}
        >
          {
            itemsFiltered.filter((i) => i.type !== 'hidden').map((item, i) => (
              <DropdownItem
                textValue={item.label}
                key={item.label}
                hideSelectedIcon
                className='flex items-start rounded-[5px] mb-1 py-3 px-4 text-white font-medium'
              >
                {item.label}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>

      {
        subFilters.map((subFilter) => {
          const currentSubFilter = filters.find((item) => item.label === subFilter);
          if (!currentSubFilter) return null;

          const setKeys = currentSubFilter.value
            ? new Set([currentSubFilter.value].flat())
            : undefined;

          // DATE FILTER
          if (currentSubFilter.type === 'date') {
            const valueDates = currentSubFilter?.value
              ? (
                  {
                    start: parseDate((currentSubFilter.value as SchemaDate)?.start ?? ''),
                    end: parseDate((currentSubFilter.value as SchemaDate)?.end ?? ''),
                  }
                )
              : undefined;
            return (
              <Popover
                key={subFilter}
                radius='sm'
                classNames={{
                  content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0 py-5',
                }}
                placement='bottom-start'
              >
                <Badge
                  content={<IconX height={10} width={10} />}
                  color='danger'
                  className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
                  onClick={() => removeFilter(subFilter)}

                >
                  <PopoverTrigger>
                    <Button
                      color='GrayMain'
                      variant='bordered'
                      endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
                    >
                      <div className='font-gothamBook'>
                        {subFilter}
                        {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
                      </div>
                      {
                        (typeof currentSubFilter.value === 'object' && 'start' in currentSubFilter.value ? currentSubFilter.value.start : '') + ' - ' + (typeof currentSubFilter.value === 'object' && 'end' in currentSubFilter.value ? currentSubFilter.value.end : '')
                      }
                    </Button>
                  </PopoverTrigger>
                </Badge>
                <PopoverContent
                  aria-label='Custom item styles'
                  className='p-3 w-full max-w-full rounded-md flex flex-col gap-5 '
                >
                  <RangeCalendar
                    color='secondary'
                    className='bg-frosted-dark-gradient border-complementary-gray-dark text-red-500'
                    hideDisabledDates
                    value={valueDates}
                    onChange={(d) => onSelectDate(d, subFilter)}
                    classNames={{
                      title: 'text-secondary',
                      gridHeaderCell: 'text-secondary',
                      nextButton: 'text-secondary',
                      prevButton: 'text-secondary',
                    }}
                    isDisabled={isLoading}
                  />
                </PopoverContent>
              </Popover>
            );
          }

          // HIDDEN FILTER
          if (currentSubFilter.type === 'hidden') {
            return null;
          }

          // INPUT FILTER
          if (currentSubFilter.type === 'input') {
            return (
              <Popover
                key={subFilter}
                radius='sm'
                classNames={{
                  content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0 py-5',
                }}
                placement='bottom-start'
                isOpen={lastCreated === subFilter}
                onOpenChange={(x) => setLastCreated(x ? subFilter : '')}
              >
                <Badge
                  content={<IconX height={10} width={10} />}
                  color='danger'
                  className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
                  onClick={() => removeFilter(subFilter)}
                >
                  <PopoverTrigger>
                    <Button
                      color='GrayMain'
                      variant='bordered'
                      endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
                    >
                      <div className='font-gothamBook'>ACA
                        {subFilter}
                        {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
                      </div>
                      {currentSubFilter.value as string}
                    </Button>
                  </PopoverTrigger>
                </Badge>
                <PopoverContent
                  aria-label='Custom item styles'
                  className='p-3 w-full max-w-full rounded-md flex flex-col gap-5 '
                >
                  <InputDebounce
                    placeholder='Buscar responsable'
                    startContent={
                      <IconSrSearch />
                    }
                    debouncedValue={(v) => onInputChange(v, subFilter)}
                    defaultValue={currentSubFilter.value as string}
                    value={currentSubFilter.value as string}
                    disabled={isLoading}
                  />
                </PopoverContent>
              </Popover>
            );
          }

          // ENUM FILTER
          if (!currentSubFilter.enum) return null;
          return (
            <EnumDropdown
              key={subFilter}
              subFilter={subFilter}
              removeFilter={removeFilter}
              currentSubFilter={currentSubFilter}
              setKeys={setKeys}
              onSelectValue={onSelectValue}
              isLoading={isLoading ?? false}
            />

          );
        })
      }

    </div>
  );
};

export default CustomFilters;
