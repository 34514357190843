import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { formatPriceInCOP } from '../utils';
import { IconApproved } from '../icons';

export interface QuoteAlternativeCardProps {
  title: String
  price: number
  isSelected?: boolean
  isApproved?: boolean
}

export const QuoteAlternativeCard: FC<QuoteAlternativeCardProps> = ({
  title,
  price,
  isSelected,
  isApproved,
}) => {
  return (
    <div className={twMerge('relative flex flex-col bg-complementary-gray-main w-full h-fit py-[10px] px-[15px] rounded', isSelected && 'border-[1.5px] border-primary')}>
      <span className='text-sm mb-[10px]'>
        {title}
      </span>
      <span className='bg-primary-gradient text-transparent bg-clip-text'>
        {formatPriceInCOP(price)}
      </span>
      {
        isApproved && (
          <div className='absolute -top-[40%] right-[8%]'>
            <IconApproved width={130} height={130} />
          </div>
        )
      }
    </div>
  );
};
