
export const IconVisible = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none' {...props}>
    <g clipPath='url(#clip0_3524_39535)'>
      <path d='M10.9771 15.1726C6.02968 15.0201 2.59357 12.545 0.23392 8.43308C-0.107908 7.83737 -0.0633528 7.19272 0.290951 6.60435C2.51587 2.90983 5.58626 0.38611 10.1213 0.0393121C14.2539 -0.276722 17.5741 1.32477 20.1797 4.4082C20.7543 5.08816 21.2312 5.85237 21.7071 6.60575C22.0735 7.18538 22.1035 7.84087 21.7566 8.43238C19.3681 12.5069 15.957 15.0275 10.9771 15.1726ZM10.6934 13.1746C14.1491 13.1512 16.7187 11.82 18.6474 9.39377C20.3273 7.28012 20.3466 7.84471 18.6424 5.71254C16.5283 3.0675 13.7075 1.74079 10.2243 2.02116C6.56363 2.31587 4.07459 4.3617 2.22465 7.30249C2.14196 7.43359 2.12663 7.69334 2.20398 7.82024C4.18259 11.0638 6.96498 13.0607 10.6934 13.1746Z' fill='url(#paint0_linear_3524_39535)' />
      <path d='M10.9748 3.29688C13.4015 3.29338 15.3601 5.18015 15.374 7.53432C15.3879 9.91436 13.4097 11.8672 10.9909 11.8609C8.59022 11.855 6.62515 9.92904 6.62159 7.57872C6.61802 5.21616 8.56563 3.30038 10.9752 3.29688H10.9748ZM10.9819 5.28118C9.69305 5.28677 8.6501 6.3006 8.64119 7.55634C8.63228 8.82817 9.70588 9.87206 11.0133 9.86332C12.2983 9.85458 13.3455 8.83446 13.3537 7.58326C13.3619 6.30584 12.3001 5.27524 10.9819 5.28083V5.28118Z' fill='url(#paint1_linear_3524_39535)' />
    </g>
    <defs>
      <linearGradient id='paint0_linear_3524_39535' x1='0' y1='0' x2='14.1823' y2='20.5642' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#111E3E' />
        <stop offset='0.0001' stopColor='#111E3E' />
        <stop offset='1' stopColor='#020D20' />
      </linearGradient>
      <linearGradient id='paint1_linear_3524_39535' x1='6.62158' y1='3.29688' x2='15.1836' y2='12.0473' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#111E3E' />
        <stop offset='0.0001' stopColor='#111E3E' />
        <stop offset='1' stopColor='#020D20' />
      </linearGradient>
      <clipPath id='clip0_3524_39535'>
        <rect width='22' height='15.1724' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconVisible;
