import React, { FC } from 'react';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { formatPriceInCOP } from '../utils';

export interface CustomBillItem {
  item: string
  price?: number
  quantity?: number
  discountPrice?: number
  discountPercentage?: number
}

export interface CustomBillProps {
  hideHeader?: boolean
  items: CustomBillItem[]
  totalPrice: number
}

type align = 'start' | 'center' | 'end' | undefined;
const columns = [
  {
    key: 'item',
    label: 'Item',
    align: 'start',
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    align: 'center',
  },
  {
    key: 'total',
    label: 'Total',
    align: 'end',
  }
];

const svgData = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='7' height='3' viewBox='0 0 7 3' fill='none'><path d='M3.48945 0L6.51141 3H0.467498L3.48945 0Z' fill='%23FCFCFC' /></svg>";
const encodedSvgData = svgData.replace(/'/g, '%27').replace(/ /g, '%20');

export const CustomBill: FC<CustomBillProps> = ({
  hideHeader,
  items,
  totalPrice,
}) => {
  return (
    <div className='flex flex-col w-full'>
      <div
        className='w-full h-[3px] bg-repeat bg-center' style={{
          backgroundImage: `url("${encodedSvgData}")`,
          backgroundPosition: 'center left',
        }}
      />
      <div className='flex flex-col w-full bg-white px-3'>
        <Table
          hideHeader={hideHeader}
          classNames={{
            wrapper: 'bg-transparent p-0 shadow-none custom-scrollbar',
            th: 'bg-transparent text-secondary first:pl-0 last:pr-0',
            td: 'text-dark-main text-sm [&>*]:font-gothamBook border-b border-dark-light px-0',
          }}
        >
          <TableHeader columns={columns}>
            {(column) => <TableColumn key={column.key + 'tableHeader'} align={column.align as align}>{column.label}</TableColumn>}
          </TableHeader>
          <TableBody>
            {items.map((row, index) =>
              <TableRow key={row.item + String(index)}>
                <TableCell>{row.item}</TableCell>
                <TableCell>{row?.quantity ?? ''}</TableCell>
                <TableCell>
                  <div className='flex flex-row items-center justify-end'>
                    <div className='flex flex-col'>
                      {row?.discountPrice &&
                        <span className='text-xs text-complementary-gray-dark line-through'>
                          {formatPriceInCOP(row?.discountPrice)}
                        </span>}
                      <span className='text-sm text-dark-main'>{(row.price ?? -1) >= 0 ? formatPriceInCOP(row.price ?? 0) : 'Precio por definir'}</span>
                    </div>
                    <span className='text-secondary text-xs'>{row?.discountPercentage ? `-${row.discountPercentage}%` : ''}</span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className='flex flex-row justify-between my-3 text-lg bg-secondary-gradient-hover text-transparent bg-clip-text font-gothamBold'>
          <span>Total</span>
          <span>{formatPriceInCOP(totalPrice)}</span>
        </div>
      </div>
      <div
        className='w-full h-[3px] bg-repeat bg-center transform rotate-180' style={{
          backgroundImage: `url("${encodedSvgData}")`,
          backgroundPosition: 'center right',
        }}
      />
    </div>
  );
};
