import { extendVariants } from '@nextui-org/react';
import { Radio as NextUiRadio, RadioGroup as NextUiRadioGroup, RadioGroupProps, RadioGroupProvider, RadioProps, useRadio, useRadioGroup, useRadioGroupContext } from '@nextui-org/radio';

export const Radio = extendVariants(NextUiRadio, {
  variants: {
    color: {
      alfred: {
        base: [
          'inline-flex m-0 bg-complementary-gray-main items-center',
          'max-w-full w-[235px] cursor-pointer rounded-[5px] gap-4 py-3 px-4 border-[0.5px] border-white h-12'
        ],
        labelWrapper: 'w-full',
        label: ['w-full text-sm text-white', 'group-data-[selected=true]:text-primary'],
        wrapper: 'border-white',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export const RadioGroup = extendVariants(NextUiRadioGroup, {
  variants: {
    color: {
      alfred: {
        base: 'flex flex-col gap-1 ',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export { type RadioProps, type RadioGroupProps, RadioGroupProvider, useRadio, useRadioGroup, useRadioGroupContext };
