
export const IconEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path d='M9.0517 2.89648H2.78927C2.31472 2.89648 1.85962 3.085 1.52406 3.42055C1.18851 3.7561 1 4.21121 1 4.68575V17.2106C1 17.6852 1.18851 18.1403 1.52406 18.4758C1.85962 18.8114 2.31472 18.9999 2.78927 18.9999H15.3141C15.7887 18.9999 16.2438 18.8114 16.5793 18.4758C16.9149 18.1403 17.1034 17.6852 17.1034 17.2106V10.9482' stroke='#B1FC00' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.7618 1.55585C16.1177 1.19995 16.6004 1 17.1038 1C17.6071 1 18.0898 1.19995 18.4457 1.55585C18.8016 1.91176 19.0016 2.39447 19.0016 2.8978C19.0016 3.40113 18.8016 3.88384 18.4457 4.23975L9.9467 12.7388L6.36816 13.6334L7.2628 10.0549L15.7618 1.55585Z' stroke='#B1FC00' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <defs>
      <linearGradient id='paint0_linear_1166_40304' x1='1' y1='2.89648' x2='17.1034' y2='18.9999' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <linearGradient id='paint1_linear_1166_40304' x1='6.36816' y1='1' x2='19.0016' y2='13.6334' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconEdit;
