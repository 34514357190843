
import { create } from 'zustand';

interface CustomFilterState {
  lastSelected: string
  isFilterOpen: boolean
  setLastSelected: (value: string) => void
  setIsFilterOpen: (value: boolean) => void
}

const useCustomFilterStore = create<CustomFilterState>((set) => ({
  lastSelected: '',
  isFilterOpen: false,
  setLastSelected: (value: string) => set({ lastSelected: value }),
  setIsFilterOpen: (value: boolean) => set({ isFilterOpen: value }),
}));

export default useCustomFilterStore;
