import { useCallback } from 'react';
import { useAuthStore } from '../context/authStore';
import { ResetPasswordOutput, confirmResetPassword, confirmSignIn, resetPassword, signIn, signOut } from 'aws-amplify/auth';
import { useRouter } from 'next/navigation';
import { get, patch } from '@/server/api';
import { translateCognitoError } from '../../../../../../packages/ui/utils/cognitoUtils';
import * as Sentry from '@sentry/nextjs';
import { PERMISSIONS } from '@/app/(dashboard)/Layout/constantMenu';
import { Usermodel } from '@alfred-co/types';

const useAuth = () => {
  const router = useRouter();
  const {
    isLoading,
    setIsLoading,
    isAuth,
    setIsAuth,
    errorMessage,
    setErrorMessage,
    setInfoMessage,
    infoMessage,
    user,
    setUser,
  } = useAuthStore();

  const onLogin = useCallback(async (email: string, password: string) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await signIn({ username: email, password });
      if (response.isSignedIn) {
        const me = await get<Usermodel>('/v2/me');
        setUser(me);
        setIsAuth(true);
      }
      // if user is force to change password
      if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        router.push('/change-password');
      }
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
      setIsAuth(false);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setIsAuth, setErrorMessage, router, setUser]);

  const onLogout = useCallback(async () => {
    try {
      setErrorMessage('');
      await signOut();
      setIsAuth(false);
      router.push('/login');
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
    }
  }, [setIsAuth, router, setErrorMessage]);

  const handleResetPasswordNextStep = useCallback((output: ResetPasswordOutput) => {
    const { nextStep } = output;
    const resetPasswordSteps = {
      CONFIRM_RESET_PASSWORD_WITH_CODE: `El código de confirmación fue enviado al ${nextStep.codeDeliveryDetails.deliveryMedium?.toLowerCase() ?? ''}`,
      DONE: 'La contraseña fue cambiada correctamente',
      default: '',
    };
    return resetPasswordSteps[nextStep.resetPasswordStep] || resetPasswordSteps.default;
  }, []);

  const handleResetPassword = useCallback(async (email: string) => {
    try {
      setErrorMessage('');
      setIsLoading(true);
      const output = await resetPassword({ username: email });
      setInfoMessage(handleResetPasswordNextStep(output));
      router.push(`recover?email=${email}`);
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleResetPasswordNextStep, setErrorMessage, router, setInfoMessage, setIsLoading]);

  const handleConfirmResetPassword = useCallback(async (email: string, code: string, newPassword: string) => {
    try {
      setErrorMessage('');
      setIsLoading(true);
      await confirmResetPassword(
        { username: email, confirmationCode: code, newPassword, options: { autoSignIn: true } }
      );

      const response = await signIn({ username: email, password: newPassword });
      if (response.isSignedIn) {
        // update bo password
        await patch('/v2/user/reset-password', {
          body: JSON.stringify({ password: newPassword }),
        });

        const me = await get<Usermodel>('/v2/me');
        setUser(me);
        setIsAuth(true);
        router.push('/');
      }
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
    } finally {
      setIsLoading(false);
    }
  }, [setErrorMessage, router, setIsAuth, setIsLoading, setUser]);

  const setNewPassword = useCallback(async (newPassword: string) => {
    try {
      setErrorMessage('');
      setIsLoading(true);
      await confirmSignIn({ challengeResponse: newPassword, options: { autoSignIn: true } });

      // update bo password
      await patch('/v2/user/reset-password', {
        body: JSON.stringify({ password: newPassword }),
      });

      const me = await get<Usermodel>('/v2/me');
      setUser(me);
      setIsAuth(true);
      router.push('/');
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
    } finally {
      setIsLoading(false);
    }
  }, [setErrorMessage, router, setIsAuth, setIsLoading, setUser]);

  const getMeUser = useCallback(async () => {
    try {
      const me = await get<Usermodel>('/v2/me');
      setUser(me);
      Sentry.setUser({ email: me.email });
      setIsAuth(true);
    } catch (e) {
      if ((e as Error).name) {
        setErrorMessage(translateCognitoError((e as Error).name));
      }
      setIsAuth(false);
    }
  }, [setErrorMessage, setUser, setIsAuth]);

  const userHasAccess = useCallback((permission: PERMISSIONS) => {
    if (!user) return false;
    if (permission === '*') return true;
    return user?.module?.some((module) => module.name === permission) ?? false;
  }, [user]);

  return {
    isLoading,
    setIsLoading,
    isAuth,
    setIsAuth,
    onLogin,
    onLogout,
    handleResetPassword,
    errorMessage,
    handleConfirmResetPassword,
    infoMessage,
    setNewPassword,
    user,
    getMeUser,
    userHasAccess,
  };
};

export default useAuth;
