
export const IconHidden = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none' {...props}>
    <g clipPath='url(#clip0_3524_38203)'>
      <path d='M2.47017 0.976562C2.73439 1.11858 2.92154 1.19234 3.07786 1.30573C8.88501 5.54522 14.6888 9.78801 20.4938 14.0297C20.8538 14.2928 21.085 14.5989 20.7987 15.0425C20.5698 15.3981 20.1679 15.4047 19.7001 15.0623C13.9094 10.8327 8.121 6.59986 2.33036 2.36918C2.03423 2.1523 1.7535 1.9046 1.92964 1.51599C2.02652 1.30242 2.27972 1.15931 2.47017 0.976562Z' fill='white' />
      <path d='M3.5898 4.625C3.94758 4.88811 4.27785 5.13251 4.57068 5.34828C3.48521 6.52732 2.42727 7.67884 1.38033 8.81605C4.92076 13.2603 9.91546 15.1142 14.7692 13.2427C15.0852 13.1205 15.3098 13.1425 15.5575 13.3605C15.7887 13.5642 16.0507 13.7326 16.3534 13.9561C15.2933 14.5329 14.21 14.8841 13.0783 15.0768C8.86632 15.7945 5.30497 14.4239 2.2192 11.6211C1.50473 10.9727 0.851908 10.2505 0.226608 9.51511C0.0537699 9.31145 -0.0717305 8.83367 0.0460637 8.65312C1.02365 7.15042 2.18948 5.80404 3.5898 4.625Z' fill='white' />
      <path d='M19.4635 11.9473C19.0309 11.628 18.7105 11.3925 18.356 11.1304C19.1112 10.3973 19.8268 9.7026 20.5831 8.96831C17.9421 5.56659 15.0292 2.8221 10.3912 3.03897C9.79448 3.06649 9.18569 3.16447 8.61763 3.34171C8.10242 3.50244 7.71161 3.44519 7.33291 3.08301C7.17658 2.93329 6.98833 2.81769 6.78467 2.51385C7.64446 2.28597 8.49323 1.98102 9.36733 1.84341C12.9947 1.27646 16.0739 2.50945 18.6852 4.94129C19.8125 5.99153 20.7482 7.24764 21.7666 8.41567C22.0979 8.79548 22.066 9.17198 21.7236 9.53637C20.975 10.3301 20.2308 11.126 19.4635 11.9451V11.9473Z' fill='white' />
      <path d='M13.6084 11.9454C12.0793 13.2158 9.7432 13.1563 8.14912 11.8595C6.42955 10.4603 6.25561 8.1055 6.85338 7.01562C7.122 7.20828 7.40713 7.37671 7.64382 7.59909C7.76601 7.71358 7.87941 7.93156 7.86179 8.08788C7.59098 10.5076 10.0603 12.2668 12.2422 11.308C12.3963 11.2397 12.6539 11.2958 12.8169 11.3817C13.0877 11.5237 13.3211 11.7362 13.6073 11.9454H13.6084Z' fill='white' />
      <path d='M15.3593 8.92359C14.7175 8.4403 14.112 8.14967 13.8544 7.34272C13.4603 6.10864 12.4728 5.37215 11.1705 5.36664C10.3041 5.36334 9.8263 4.86464 9.16797 4.39897C10.8655 3.75495 12.3759 3.92889 13.7267 5.00115C14.9487 5.96993 15.4837 7.26566 15.3593 8.92359Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_3524_38203'>
        <rect width='22' height='14.339' fill='white' transform='translate(0 0.976562)' />
      </clipPath>
    </defs>
  </svg>
);

export default IconHidden;
